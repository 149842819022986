$largeurRef: 1920;
$hauteurRef: 1080;
$couleurPrincipalVert: #74d14c;
$couleurPrincipalBleu: #4a98ff;
$couleurPrincipalGris: #41494f;
$couleurPrincipalBlanc: #fff;
$couleurSecondaireBlanc: #eee;
$couleurBordurePrincipal: #aaa;

@function redimLargeur($largeur) {
  @return ($largeur / $largeurRef) * 100vw;
}

@function redimHauteur($hauteur) {
  @return ($hauteur / $hauteurRef) * 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.attribute-hide.mapboxgl-map > .mapboxgl-control-container {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

button.react-datepicker__close-icon::after {
  background-color: $couleurPrincipalGris;
}

.react-datepicker-wrapper > .react-datepicker__input-container {
  width: 100%;
  height: 100%;
  line-height: 0;
}

.react-datepicker > .react-datepicker__triangle {
  display: none;
}


.selection {
  width: 100%;
  height: 100%;
}

.selection.selection-position-type {
  width: 70%;
  height: 100%;
}

.selection.selection-position-direction {
  width: 0.25 * 70%;
  height: 100%;
}

.selection > div:first-of-type {
  border-radius: 0;
  border-width: 0;
  box-shadow: none;
  width: 100%;
  min-height: auto;
  height: 100%;
}

button > [role='progressbar'] > div {
  height: redimHauteur(24);
  width: redimHauteur(24);
}

.selection:not(.selection-multi) {
  & > div:first-of-type > div, & > div:first-of-type > div > div, & > div:first-of-type > div > div > div {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.selection.selection-multi {
  & > div:first-of-type > div, & > div:first-of-type > div:not(:first-of-type) > div {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.selection.selection-sans-focus > div:last-of-type:not(:first-of-type) {
  display: none;
}

.leaflet-container path.selection {
  animation: selection ease 1s infinite reverse;
  @keyframes selection {
    50% {
      stroke: $couleurPrincipalBlanc;
    }
  }
}

.leaflet-container path.en-retard {
  animation: en-retard ease 1s infinite reverse;
  @keyframes en-retard {
    50% {
      stroke: #A740F3;
    }
  }
}

.leaflet-container path.non-valide {
  animation: non-valide ease 1s infinite reverse;
  @keyframes non-valide {
    50% {
      stroke: #6c757d;
    }
  }
}


.leaflet-container path.en-attente {
  animation: en-attente ease 1s infinite reverse;
  @keyframes en-attente {
    50% {
      stroke: #dc3545;
    }
  }
}


.leaflet-container path.en-cours {
  animation: en-cours ease 1s infinite reverse;
  @keyframes en-cours {
    50% {
      stroke: orange;
    }
  }
}


.leaflet-container path.cloture {
  animation: cloture ease 1s infinite reverse;
  @keyframes cloture {
    50% {
      stroke: #28a745;
    }
  }
}

.btn.btn-retard {
  background-color: #A740F3;
}

.btn.btn-retard:hover {
  background-color: darkorchid;
}

.btn.btn-retard.active {
  background-color: #8821BB;
}

.btn.btn-en-cours {
  background-color: orange;
}

.btn.btn-en-cours:hover {
  background-color: darkorange;
}

.btn.btn-en-cours.active {
  background-color: #EE7B00;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  border-radius: redimHauteur(4)
}

.pagination > li {
  display: inline
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: redimHauteur(6) redimLargeur(12);
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: redimLargeur(1) solid #ddd;
  margin-left: redimLargeur(-1);
  font-size: redimHauteur(16);
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: redimHauteur(4);
  border-top-left-radius: redimHauteur(4);
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: redimHauteur(4);
  border-top-right-radius: redimHauteur(4);
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: default
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: redimHauteur(10) redimLargeur(16);
  font-size: redimHauteur(20);
  line-height: 1.3333333;
}

@for $i from 1 through 9 {
  .pagination-lg > li.important-#{$i}:not(.non-important):nth-child(#{$i}) {
    & > a, & > span {
      border-top: redimHauteur(8) solid $couleurPrincipalVert;
      padding-top: redimHauteur(7.5);
      padding-bottom: redimHauteur(7.5);
    }

    &.active {
      & > a, & > span {
        border-top-color: $couleurPrincipalGris;
      }
    }
  }
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: redimHauteur(6);
  border-top-left-radius: redimHauteur(6);
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: redimHauteur(6);
  border-top-right-radius: redimHauteur(6);
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: redimHauteur(5) redimLargeur(10);
  font-size: redimHauteur(12);
  line-height: 1.5
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: redimHauteur(3);
  border-top-left-radius: redimHauteur(3);
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: redimHauteur(3);
  border-top-right-radius: redimHauteur(3);
}

.modal-content > .modal-header {
  justify-content: center;
}

@for $i from 0 through 100 {
  .modal-dialog.modal-w#{$i} {
    max-width: #{$i}vw;
  }
}

svg > g > g[transform="translate(0,0)"] > text[text-anchor="end"] {
  font-weight: bold;
}

svg > g > g[transform="translate(0,0)"] > g > line[x1="0"][x2="0"] {
  display: none;
}
